import {
  BoxFormStyled,
  ErrorMsgsStyled,
  LabelStyled,
  SubmitButtonStyled,
} from '@Usician/styles/Form.styled';
import { Heading1Styled } from '@Usician/styles/Text.styled';

import React from 'react';

import Form from '@hangar31/rc-form';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';

import { withAuth } from '@Usician/withGraph';
import { withClient } from '@Usician/withGraph';

type FormData = { email: string; password: string };

function Home({ client }: HomePageProps) {
  const router = useRouter();
  return (
    <BoxFormStyled>
      <Form
        onSubmit={async (fields: FormData) => {
          const { data } = await client.login(fields);
          if (data?.token) {
            new Cookies().set('usician-token', data.token, { path: '/' });
          } else throw Error('Something has gone wrong.');
        }}
        onSuccess={() => {
          router.push('/redirect');
        }}
        defaultData={{} as FormData}
      >
        <Heading1Styled>Member Log In</Heading1Styled>
        <div css={{ margin: '0 auto', maxWidth: '45rem', padding: '2rem' }}>
          <LabelStyled>
            <span>Email</span>
            <input required type="email" name="email" />
          </LabelStyled>
          <Link passHref href="/forgot-account">
            <a
              css={{
                display: 'block',
                marginBottom: '1.2rem',
                textAlign: 'right',
              }}
            >
              Forgot Account?
            </a>
          </Link>
          <LabelStyled>
            <span>Password</span>
            <input required type="password" name="password" />
          </LabelStyled>
          <Link passHref href="/forgot">
            <a
              css={{
                display: 'block',
                marginBottom: '1.2em',
                textAlign: 'right',
              }}
            >
              Forgot Password?
            </a>
          </Link>
        </div>
        <Link passHref href="/sign-up">
          <a css={{ display: 'inline-block', marginTop: '2rem' }}>
            New Member? &raquo;
          </a>
        </Link>
        <ErrorMsgsStyled />
        <SubmitButtonStyled
          lg
          stateCSS={{
            WAITING: {},
          }}
        >
          Next &raquo;
        </SubmitButtonStyled>
      </Form>
    </BoxFormStyled>
  );
}

export type HomePageProps = ServerSidePagePropsWithClient<
  typeof getServerSideProps
>;
export const getServerSideProps = withAuth(async () => {
  return {
    props: { layout: 'PUBLIC' },
  };
}, 'REDIRECT');
export default withClient(Home);
